@import "./shared.scss";

a {
  color: $writing;
  text-decoration: none;
}

body {
  padding: 2% 5% 2% 5%;
  background-color: $background;
  color: $writing;
  text-align: center;
  font-size: 16pt;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

form {
  margin: 0 !important;
}

html * {
  margin: 0;
}

li a {
  display: block;
  text-align: center;
  font-family: serif !important;
}

li a:hover {
  text-decoration: underline;
}

nav {
  padding: 2% 0 4% 0;
  font-size: 20pt;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

p {
  margin: 0;
}

p b a:hover {
  text-decoration: underline;
}

span.error {
  font-size: 12pt;
  font-weight: bold;
}

span.iconUni::before {
  content: "\f19c";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.iconPaper::before {
  content: "\f1ea";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.iconLap::before {
  content: "\f109";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  width: 100%;
}

table tr td {
  vertical-align: text-top;
}

table tr td a {
  color: $link;
}

table tr td a:hover {
  text-decoration: underline;
  color: $writing;
  text-decoration-color: $writing;
}

#button {
  text-align: center;
  background-color: $writing;
  font-size: 14pt;
  padding: 2% 3%;
  margin-top: 2%;
  border-radius: 10px;
}

#contactTitle {
  margin-bottom: 2% !important;
}

#cv {
  font-family: 'Roboto', sans-serif !important;
  font-size: 10pt;
  padding: 1.4% 2%;
  border-radius: 20px;
  background-color: $icon;
  color: $background;
  vertical-align: text-top;
}

#cv:hover {
  background-color: $writing;
}

#delta {
  line-height: 1;
  font-size: 60pt;
}

#github {
  width: 50%;
  float: left;
}

#github:hover {
  transform: scale(1.2);
}

#keybase {
  width: 50%;
  float: right;
}

#keybase:hover {
  transform: scale(1.2);
}

#message {
  resize: none;
}

#myName {
  font-size: 22pt;
  padding-bottom: 1%;
  border-bottom: solid $writing;
  font-weight: normal;
  text-align: right;
  margin: 0 !important;
}

#pph-hireme {
  padding-bottom: 2%;
}

#top {
  height: 40pt
}

.alignLeft {
  width: 50%;
  float: left;
  text-align: left;
}

.alignRight {
  width: 50%;
  float: right;
  text-align: right;
}

.body {
  margin: 0 15% 6% 15%;
  text-align: left;
}

.buttonPlacement {
  margin-top: 2%;
  text-align: center;
}

.center {
  text-align: center;
}

.computingSubTitle {
  text-align: right;
  font-size: 16pt;
  font-weight: bold;
}

.computingTable {
  border-spacing: 0 15px;
  margin: -15px 0 -15px 0;
}

.computingWriting {
  padding-left: 2%;
  font-size: 12pt;
}

.contact-details {
  display: flex;
  flex-direction: row;
}

.contact-details .left {
  padding-top: 2%;
}

.contact-details .right {
  flex: 1;
  padding-left: 3%;
}

.employerName {
  font-size: 18pt;
}

.error {
  color: $error;
}

.iconLink {
  font-size: 20pt;
  margin-right: 2%;
  color: $icon;
}

.iconLink:hover {
  color: $writing;
}

.jobDates {
  margin-bottom: 2%;
  font-size: 12pt;
}

.jobDetails {
  font-size: 12pt;
}

.jobTitle {
  margin-bottom: 2%;
  font-size: 14pt;
}

.link-color {
  color: $link;
}

.link-color:hover {
  text-decoration: underline;
  color: $writing;
  text-decoration-color: $writing;
}

.location {
  font-size: 14pt;
  text-align: right;
}

.logos {
  padding-top: 5%;
}

.marginBottom2 {
  margin-bottom: 2%;
}

.response {
  width: 100%;
  box-sizing: border-box;
  padding: 2%;
  font-size: 16px;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.spacing2 {
  margin-bottom: 2%;
}

.spacing4 {
  margin-bottom: 4%;
}

.spacing6 {
  margin-bottom: 6%;
}

.statusMessage {
  font-size: 12pt;
  font-weight: bold;
}

.success {
  color: $success;
}

.tinySpacing {
  padding: 3% 0;
}

.title {
  font-size: 24pt;
  margin-bottom: 4%;
  font-family: serif !important;
}

.uniCourseDetails {
  font-size: 12pt;
}

.writing {
  font-size: 12pt;
  text-align: left;
}

.writing-justify {
  font-size: 12pt;
  text-align: justify;
  text-align-last: left;
}

@media only screen and (max-width: 800px) {

  li a {
    display: block;
    text-align: center;
    font-family: serif !important;
    padding: 2%;
  }

  .body {
    margin: 0 0 6% 0;
    text-align: left;
  }

  .contact-details {
    flex-direction: column;
  }

  .contact-details .left {
    text-align: center;
    padding-top: 0 !important;
  }

  .contact-details .right {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 600px) {

  ul {
    flex-direction: column;
  }

  #cv {
    font-family: 'Roboto', sans-serif !important;
    font-size: 8pt;
    padding: 1.4% 2%;
    border-radius: 20px;
    background-color: $writing;
    color: $background;
    vertical-align: super;
  }

  #myName {
    text-align: center;
  }

  #pph-hireme {
    padding-bottom: 0 !important;
  }

  #top {
    height: 80pt !important;
  }

  .alignLeft {
    text-align: center;
    float: none;
    width: auto;
    height: auto;
  }

  .alignRight {
    text-align: right;
    float: none;
    width: auto;
    height: auto;
  }

  .buttonPlacement {
    text-align: left;
  }

  .iconLink {
    font-size: 16pt;
    margin-right: 4%;
    float: left;
  }

  .statusMessage {
    text-align: center;
  }

  span.iconUni::before {
    display: none !important;
  }

  span.iconPaper::before {
    display: none !important;
  }

  span.iconLap::before {
    display: none !important;
  }
}

body::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}
